/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {

  font-family: 'Product Sans';

  font-style: normal;

  font-weight: 400;

  src: local('Product Sans'), url('https://fonts.cdnfonts.com/s/13998/ProductSans-Regular.woff') format('woff');

}